import { ApiLayer } from '@/utils/api/api-layer';
import axios from '@/utils/api/client/axios';
class PaymentLayer extends ApiLayer {
    /**
     * Получение нужной информации для формирования оплаты
     * @param id
     */
    fetchPaymentInfo(id) {
        return this.methods.get(`/orders/${id}/payment_info`);
    }
}
export const PaymentAPI = new PaymentLayer(axios);
