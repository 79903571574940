<template>
  <div :class="$style.wrapper">
    <div :class="$style.sum">
      {{ $trlMessage('event.price.total.title') }}:&nbsp;
      <span :class="hasDiscount ? $style.strike : $style.bold">
        {{ $trlMessage('event.price.total.sum', { price: sumWithOutDiscount }) }}
      </span>
      <strong :class="$style.discount" v-if="hasDiscount">
        {{ $trlMessage('event.price.total.sum', { price: sumWithDiscount }) }}
      </strong>
    </div>
    <promo-code
      v-if="showPromoCode"
      :promo-code="promoCodeName"
      :editable="editable"
      :event-id="eventId"
      :tariff-id="tariffId"
      @set-code="handleSetPromoCode"
    />
  </div>
</template>

<script>
import PromoCode from '@/components/promo-code/promo-code.vue';

export default {
  name: 'TotalPrice',
  components: { PromoCode },
  props: {
    showPromoCode: {
      type: Boolean,
      default: true
    },
    price: {
      type: [Number, String],
      default: null
    },
    // Номинальная стоимость билета, без учета скидок
    nominalPrice: {
      type: [Number, String],
      default: null
    },
    countParticipants: {
      type: Number,
      default: 1
    },
    promoCode: {
      type: Object,
      default: () => ({})
    },
    editable: {
      type: Boolean,
      default: true
    },
    eventId: {
      type: String,
      default: ''
    },
    tariffId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      customerCode: {
        price_with_discount: 1
      }
    };
  },
  emits: ['set-code'],
  computed: {
    promoCodeName() {
      return this.customerCode?.code || '';
    },
    sumWithOutDiscount() {
      if (this.editable || !this.hasDiscount) {
        return this.price * this.countParticipants;
      }
      return +this.nominalPrice;
    },
    sumWithDiscount() {
      if (this.editable) {
        return this.hasDiscount
          ? this.customerCode?.price_with_discount * this.countParticipants
          : 0;
      }
      return this.price * this.countParticipants;
    },
    hasDiscount() {
      return !!this.customerCode?.code;
    }
  },
  mounted() {
    this.customerCode = this.promoCode;
  },
  methods: {
    handleSetPromoCode(code) {
      this.customerCode = code;
      this.$emit('set-code', code);
    }
  }
};
</script>

<style module>
.wrapper {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.sum {
  margin-right: 4px;
  display: flex;
  align-items: baseline;
  white-space: nowrap;
}

.bold {
  font-weight: bold;
}

.strike {
  text-decoration: line-through;
}

.discount {
  display: inline-block;
  margin-left: 4px;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 600px) {
  .sum,
  .wrapper {
    flex-direction: column;
  }
}
</style>
