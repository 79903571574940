import { immerable, produce } from 'immer';
export class ApiLayer {
    client;
    cancelTokenSource;
    [immerable] = true;
    constructor(apiClient) {
        this.client = apiClient;
        return new Proxy(this, {
            get: (target, prop, receiver) => {
                const value = Reflect.get(target, prop, receiver);
                if (typeof value !== 'function') {
                    return value;
                }
                const fn = value.bind(produce(target, (draft) => {
                    draft.cancelTokenSource = this.client.createCancelTokenSource();
                }));
                Object.defineProperty(fn, 'name', {
                    value: value.name,
                    writable: false
                });
                return fn;
            }
        });
    }
    get methods() {
        if (!this.cancelTokenSource) {
            throw new Error('не указан cancelTokenSource');
        }
        return this.client.getMethods(this.cancelTokenSource);
    }
    filterCanceledRequest(exception) {
        if (!this.client.isCancel(exception)) {
            return Promise.reject(exception);
        }
    }
}
