<template>
  <div :class="$style.dropdown">
    <div>
      <slot :on-toggle="onToggle"></slot>
    </div>
    <transition :name="transitionName">
      <div v-if="isOpen" :class="$style.container">
        <slot name="menu" :on-toggle="onToggle"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'BaseDropdown',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  computed: {
    transitionName() {
      return this.isOpen ? this.$style.slideFadeEnterActive : '';
    }
  },
  methods: {
    onToggle() {
      if (this.disabled) {
        return;
      }
      this.toggle();
    },
    toggle() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        document.addEventListener('click', this.closeDropdown);
      } else {
        document.removeEventListener('click', this.closeDropdown);
      }
    },
    closeDropdown(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        document.removeEventListener('click', this.closeDropdown);
      }
    },
    handleKeyPress(event) {
      if (event.key === 'Escape') {
        this.closeDropdown();
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyPress);
    document.addEventListener('click', this.closeDropdown);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
    document.removeEventListener('click', this.closeDropdown);
  }
};
</script>

<style module>
.dropdown {
  position: relative;
  display: inline-block;
}

.container {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.slideFadeEnterActive {
  transition: opacity 0.3s ease;
}

.slideFadeEnter,
.slideFadeLeaveTo {
  opacity: 0;
}
</style>
