<template>
  <nav :class="[$style.menuWrapper, isMobileMenuOpen ? $style.open : '']" ref="menu">
    <div :class="$style.mobileIcon" @click="toggleMobileMenu">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <ul :class="$style.menu">
      <li :class="$style.item" v-for="route in menuList" :key="route.path">
        <router-link :to="route.path" :class="$style.link">{{ route.meta.title }}</router-link>
      </li>
      <li :class="$style.item">
        <help-link :class="$style.link" />
      </li>
      <li :class="$style.item">
        <base-button kind="link" :class="$style.link" @click="handleClickLogout">
          {{ trlMessage('button.logout') }}
        </base-button>
      </li>
    </ul>
  </nav>
</template>

<script>
import { trlMessage } from '@/services/i18n';
import { updateUser } from '@/utils/user';
import { clearLocalStorage } from '@/utils/local-storage';
import BaseButton from '@/components/base-button/base-button.vue';
import HelpLink from '@/components/help-link/help-link.vue';

export default {
  name: 'ClubMenu',
  components: {
    BaseButton,
    HelpLink
  },
  data() {
    return {
      isMobileMenuOpen: false
    };
  },
  computed: {
    menuList() {
      return this.$router.options.routes.filter((item) => item.meta?.menu);
    }
  },
  methods: {
    trlMessage,
    handleClickLogout() {
      updateUser();
      clearLocalStorage();
      this.$router.push({ name: 'login' });
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
      if (this.isMobileMenuOpen) {
        document.addEventListener('click', this.closeMenuOutside);
      } else {
        document.removeEventListener('click', this.closeMenuOutside);
      }
    },
    closeMenuOutside(event) {
      if (!this.$refs.menu?.contains(event.target)) {
        this.isMobileMenuOpen = false;
        document.removeEventListener('click', this.closeMenuOutside);
      }
    }
  }
};
</script>

<style module>
.menuWrapper {
  &.open {
    @media screen and (max-width: 600px) {
      background-color: #fff;
      display: flex;
      box-shadow: 0 4px 14px rgb(0, 0, 0, 0.05);
    }
  }
}

.open {
  position: absolute;
  top: 0;
  right: 0;
}

.open .menu {
  @media screen and (max-width: 600px) {
    margin: 0;
    display: flex;
  }
}

.open .mobileIcon {
  @media screen and (max-width: 600px) {
    margin: 10px;
  }
}

.menu {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  list-style-type: none;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    display: none;
  }
}

.item {
  padding: 0 10px;
  &:last-child {
    border-right: none;
  }

  @media screen and (min-width: 600px) {
    border-right: solid 1px #d8d7e0;
  }
}

.link {
  color: #373546;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 600px) {
    display: block;
    padding: 10px 20px 10px 0;
  }
}

.mobileIcon {
  display: none;

  @media screen and (max-width: 600px) {
    display: block;
    cursor: pointer;
  }

  span {
    @media screen and (max-width: 600px) {
      display: block;
      width: 25px;
      height: 3px;
      margin: 5px;
      background-color: #333;
      transition: all 0.3s ease-in-out;
    }
  }
}

@media (max-width: 600px) {
  .menuWrapper.open .mobileIcon span:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 5px);
  }

  .menuWrapper.open .mobileIcon span:nth-child(2) {
    display: none;
  }

  .menuWrapper.open .mobileIcon span:nth-child(3) {
    transform: rotate(45deg) translate(0px, -1px);
  }
}
</style>
