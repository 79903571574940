import { ApiLayer } from '@/utils/api/api-layer';
import axios from '@/utils/api/client/axios';
class PromoCodeLayer extends ApiLayer {
    fetchCodeInfo(uuid, tariffId, code) {
        const params = {
            code
        };
        return this.methods.get(`/public/events/${uuid}/tariff_group/${tariffId}/promo_code`, { params });
    }
}
export const PromoCodeAPI = new PromoCodeLayer(axios);
