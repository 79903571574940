<template>
  <base-button :loading="loading" @click="handleFetchInvoice">
    {{ $trlMessage('ticket.invoice') }}
  </base-button>
</template>

<script>
import BaseButton from '@/components/base-button/base-button.vue';
import { OrderAPI } from '@/api/order';
import { EventType } from '@/types/event';
import { sendMetrics } from '@/utils/metrics';

export default {
  name: 'InvoiceButton',
  components: {
    BaseButton
  },
  props: {
    id: {
      type: String,
      required: true
    },
    initPrint: {
      type: Boolean,
      default: false
    },
    ticketType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false
    };
  },
  mounted() {
    if (this.initPrint) {
      this.initialPrint();
    }
  },
  methods: {
    initialPrint() {
      this.loading = true;
      const type = EventType[this.ticketType];
      const ticketTypeTarget = `buy_nayom24_${type}`;
      sendMetrics(ticketTypeTarget);
      OrderAPI.fetchInvoiceById(this.id)
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `invoice_${this.id}.pdf`;
          link.click();
          document.getElementById('app');
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error('Ошибка при загрузке PDF:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleFetchInvoice() {
      this.initialPrint();
    }
  }
};
</script>

<style module></style>
