<template>
  <page-layout>
    <page-inner :loading="init">
      <h4>{{ title }}</h4>
      <div :class="$style.status">
        <span>{{ $trlMessage('event.state', { state: orderStatus }) }}</span>
        <span v-if="canPay && !disabledEdit">
          {{ $trlMessage('event.pay.date', { date: payDate }) }}
        </span>
      </div>
      <validation-provider :resolver="resolver" :default-values="defaultValues" @submit="onSubmit">
        <template #default="{ handleSubmit }">
          <form method="post" @submit.prevent="handleSubmit" @keyup.enter="handleSubmit">
            <event-participant
              :disabled="disabledEdit"
              :price="price"
              :nominal-price="ticket.nominal_cost"
              :can-remove="false"
              :is-me="isMe"
              :promo-code="promoCode"
            />
            <div :class="$style.btnGroup">
              <div v-if="!disabledEdit">
                <pay-button
                  v-if="canPay && isCard"
                  :ticket-id="ticketId"
                  :init-pay="isPayParams"
                  :ticket-type="ticketType"
                  :personal="isPersonal"
                  @success="handleSuccessPay"
                />
                <invoice-button
                  v-if="isBill"
                  :init-print="isPayParams"
                  :id="ticketId"
                  :ticket-type="ticketType"
                />
              </div>

              <base-button
                v-if="!isMe"
                :disabled="disabledEdit"
                kind="white"
                @click="handleSubmit"
                :loading="isSaving"
              >
                {{ $trlMessage('button.save') }}
              </base-button>
            </div>
          </form>
        </template>
      </validation-provider>
    </page-inner>
  </page-layout>
</template>

<script>
import { object, string, array } from 'yup';
import { ValidationProvider, yupResolver, setSubmitError } from '@/components/ui/validate-form';
import PageLayout from '@/components/page/page-layout.vue';
import PageInner from '@/components/page/page-inner.vue';
import BaseButton from '@/components/base-button/base-button.vue';
import { OrderAPI } from '@/api/order';
import { localeDateShort, localeDate } from '@/utils/common';
import { EventPaymentType } from '@/types/event';
import { PaymentType } from '@/types/payment';
import EventParticipant from './components/event-participant.vue';
import InvoiceButton from './components/invoice-button.vue';
import PayButton from './components/pay-button.vue';

const userData = {
  first_name: '',
  last_name: '',
  email: '',
  position: '',
  company: ''
};
export default {
  name: 'TicketPage',
  components: {
    BaseButton,
    EventParticipant,
    PageLayout,
    PageInner,
    InvoiceButton,
    PayButton,
    ValidationProvider
  },
  props: {
    ticketId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      init: true,
      ticket: null,
      participantList: [],
      participantCount: 0,
      isSaving: false,
      isMe: false
    };
  },
  computed: {
    isPayParams() {
      return this.$route.hash === '#pay';
    },
    title() {
      const name = this.ticket.event?.name;
      const dateRaw = this.ticket.event?.start_date;
      const date = dateRaw ? localeDate(dateRaw) : '';
      return this.$trlMessage('event.pay.title', { event: name, date });
    },
    disabledEdit() {
      const today = new Date();
      const payDay = new Date(this.ticket.pay_until);

      return payDay.getTime() < today.getTime();
    },
    canPay() {
      return this.ticket.state === EventPaymentType.created;
    },
    price() {
      return this.ticket.price_per_participant || 0;
    },
    isBill() {
      return this.ticket.payment_type === PaymentType.bill;
    },
    isCard() {
      return this.ticket.payment_type === PaymentType.card;
    },
    payDate() {
      return localeDateShort(this.ticket.pay_until);
    },
    orderStatus() {
      const state = {
        [EventPaymentType.created]: this.$trlMessage('event.payment.created'),
        [EventPaymentType.canceled]: this.$trlMessage('event.payment.canceled'),
        [EventPaymentType.paid]: this.$trlMessage('event.payment.paid')
      };
      return state[this.ticket.state];
    },
    resolver() {
      const resolver = {};
      function createRequiredString() {
        return string().when('email', (email) => {
          if (email[0]) {
            return string().required();
          }
          return string();
        });
      }

      resolver.participants = array().of(
        object({
          email: string(),
          first_name: createRequiredString(),
          last_name: createRequiredString(),
          position: createRequiredString(),
          company: createRequiredString()
        })
      );
      return yupResolver(object(resolver));
    },
    defaultValues() {
      return {
        participants: this.participantList
      };
    },
    ticketType() {
      return this.ticket?.tariff_group?.type;
    },
    isPersonal() {
      // Это настолько костыль, что моим глазам больно, но в данный момент нельзя менять схему т.к. релиз завтра
      // максимально отвратительно. Настолько отвратительно, что хотелось записать комментарий голосовухой
      return !!this.ticket?.tariff_group?.name.toLowerCase().match('персонал');
    },
    promoCode() {
      return this.ticket.promo_code || {};
    }
  },
  watch: {},
  mounted() {
    OrderAPI.fetchOrderById(this.ticketId)
      .then((ticket) => {
        this.ticket = ticket;
        this.participantList = ticket.participants;
        this.participantCount = ticket.number_of_participants;

        const participantCount = this.participantList.length;

        this.isMe = this.participantList[0]?.me;

        if (participantCount < this.participantCount) {
          for (let i = participantCount; i < this.participantCount; i += 1) {
            this.participantList.push({ ...userData });
          }
        }
      })
      .catch(() => {
        this.$router.push({ name: 'orders' });
      })
      .finally(() => {
        this.init = false;
      });
  },
  methods: {
    onSubmit(value, { setError }) {
      const participants = value.participants.filter((participant) => participant.email);
      this.isSaving = true;
      OrderAPI.updateParticipants(this.ticketId, { participants })
        .catch((error) => {
          return setSubmitError(error, { setError });
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    handleSuccessPay() {
      this.ticket.state = EventPaymentType.paid;
    }
  }
};
</script>

<style module>
.status {
  display: flex;
  flex-direction: column;
  margin: -10px 0 20px;
}

.btnGroup {
  display: flex;
  justify-content: space-between;
}
</style>
