<template>
  <h4>NotFound</h4>
</template>

<script>
export default {
  name: 'NotFound',
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {}
};
</script>

<style module></style>
