<template>
  <div :class="$style.root">
    <div v-if="label" :class="$style.labelWrapper">
      <label :for="id" :class="$style.label">
        {{ label }}
      </label>
      <slot name="action" />
    </div>
    <slot :id="id" />
    <div :class="$style.error">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormField',
  props: {
    label: {
      type: String,
      required: false,
      default: null
    },
    error: {
      type: String,
      required: false,
      default: () => {}
    },
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    hasError() {
      return !!this.error;
    }
  }
};
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
}

.labelWrapper {
  margin-bottom: 0.9rem;
  display: flex;
  justify-content: space-between;
}

.error {
  font-style: 1.4rem;
  margin-top: 0.8rem;
  color: #db525e;
}
</style>
