import { ApiLayer } from '@/utils/api/api-layer';
import axios from '@/utils/api/client/axios';
class EventLayer extends ApiLayer {
    /**
     * Получение всей информации о событии
     * @param id
     */
    fetchEventById(id) {
        return this.methods.get(`/public/events/${id}`);
    }
    /**
     * Получение информации по выбранному тарифу события
     * @param eventID
     * @param tariffID
     */
    fetchEventTariffById(eventID, tariffID) {
        return this.methods.get(`/public/events/${eventID}/tariff_group/${tariffID}/current_tariff`);
    }
}
export const EventAPI = new EventLayer(axios);
