export function isCancel(error) {
    return error instanceof CancelableError;
}
export function filterCanceledRequest(exception) {
    if (!isCancel(exception)) {
        return Promise.reject(exception);
    }
}
const symbol = Symbol('innerOnCancel');
class CancelableError extends Error {
    __CANCEL__ = true;
}
function isInnerOnCancel(onCancel) {
    return !!onCancel?.hasOwnProperty(symbol);
}
export class CancelablePromise {
    promise;
    onCancel;
    _canceled = false;
    constructor(promise, onCancel) {
        if (isInnerOnCancel(onCancel)) {
            this.promise = promise;
            this.onCancel = onCancel;
        }
        else {
            let wrapReject;
            let wrapResolve;
            this.promise = new Promise((resolve, reject) => {
                wrapResolve = resolve;
                wrapReject = reject;
            });
            promise.then((res) => wrapResolve(res), (err) => wrapReject(err));
            this.onCancel = ((message) => {
                wrapReject(new CancelableError(message));
                if (onCancel) {
                    onCancel(message);
                }
            });
            this.onCancel[symbol] = true;
        }
        this.promise.catch((err) => {
            if (isCancel(err)) {
                this._canceled = true;
            }
        });
    }
    catch(onrejected) {
        return new CancelablePromise(this.promise.catch(onrejected), this.onCancel);
    }
    then(onfulfilled, onrejected) {
        return new CancelablePromise(this.promise.then(onfulfilled, onrejected), this.onCancel);
    }
    finally(onfinally) {
        return new CancelablePromise(this.promise.finally(onfinally), this.onCancel);
    }
    static resolve(value, onCancel) {
        return new CancelablePromise(Promise.resolve(value), onCancel);
    }
    cancel(message) {
        this._canceled = true;
        this.onCancel(message);
    }
    get canceled() {
        return this._canceled;
    }
}
