<template>
  <div v-if="visible" :class="$style.overlay" @keydown.esc="hide">
    <div :class="$style.modal">
      <header :class="$style.header">
        <button :class="$style.closeButton" @click="hide">&#x2715;</button>
      </header>
      <section :class="$style.main">
        <slot name="content"></slot>
      </section>
      <footer :class="$style.footer">
        <slot name="footer"></slot>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false
    };
  },
  emits: ['hide'],
  mounted() {
    this.visible = this.show;
  },
  watch: {
    show(state) {
      if (state) {
        this.openModal();
      } else {
        this.closeModal();
      }
    }
  },
  methods: {
    openModal() {
      this.visible = true;
      document.body.style.overflow = 'hidden'; // Запрещаем прокрутку заднего фона
    },
    closeModal() {
      this.visible = false;
      document.body.style.overflow = ''; // Восстанавливаем прокрутку заднего фона
    },
    hide() {
      this.$emit('hide');
    }
  }
};
</script>

<style module>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  min-width: 250px;
}

.header {
  display: flex;
  justify-content: flex-end;
}

.closeButton {
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: baseline;
  justify-content: center;
  background-color: transparent;
  border: none;
  font-size: 20px;
}

.main {
  margin: 12px 0;
}
.footer {
  margin: 20px 0 8px;
}
</style>
