import IntlMessageFormat from 'intl-messageformat';
import memoize from '@formatjs/fast-memoize';
import { getCookie, setCookie } from '@/utils/common';
import allMessages from '../assets/trl.json';

const formatters = {
  getNumberFormat: memoize((locale, opts) => new Intl.NumberFormat(locale, opts)),
  getDateTimeFormat: memoize((locale, opts) => new Intl.DateTimeFormat(locale, opts)),
  getPluralRules: memoize((locale, opts) => new Intl.PluralRules(locale, opts))
};

function extractLangMessages(translations, lang) {
  return Object.fromEntries(
    Object.entries(translations).map(([key, trls]) => [
      key,
      Object.entries(trls).find(([key]) => key.startsWith(lang))[1]
    ])
  );
}

const messageParserCache = new Map();
let lang = 'ru_RU';
const initLang = getCookie('lang');

if (initLang) {
  lang = initLang;
} else {
  setCookie('lang', lang);
}

const messages = extractLangMessages(allMessages, lang);

function getSourceMessage(msg, translations) {
  if (messageParserCache.has(msg)) {
    return messageParserCache.get(msg);
  }
  const result = translations[msg] || (window.Translations || {})[msg];
  return result ?? msg;
}

export function trlMessage(msg, payload = {}) {
  const result = new IntlMessageFormat(
    getSourceMessage(msg, messages),
    lang.split('_')[0],
    {
      date: {
        shortWithNumericYear: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        }
      }
    },
    { formatters }
  );

  messageParserCache.set(msg, result.getAst());

  return result.format({
    'text-bold': (str) => `<b>${str}</b>`,
    ...payload
  });
}

export const VueI18n = {
  install(Vue) {
    Vue.config.globalProperties.$trlMessage = (message, payload) => {
      return trlMessage(message, payload);
    };
  }
};
