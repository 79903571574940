<template>
  <validation-provider id="code" :resolver="resolver" @submit="onSubmit">
    <template #default="{ handleSubmit }">
      <form method="post" @submit.prevent="handleSubmit" @keyup.enter="handleSubmit">
        <form-row>
          <validation-field name="code">
            <template #default="{ modelValue, onChange, name, invalid }">
              <form-field :id="name" :error="errorMessage">
                <template #default="{ id }">
                  <base-input
                    ref="code"
                    :id="id"
                    v-mask="'######'"
                    tabindex="2"
                    :placeholder="$trlMessage('code.placeholder')"
                    :has-error="invalid"
                    :value="modelValue"
                    :name="name"
                    autofocus
                    @input="onChange($event), setValue()"
                  />
                </template>
              </form-field>
            </template>
          </validation-field>
        </form-row>
        <form-row>
          <form-field id="submit">
            <base-button tabindex="3" :loading="loading" @click="handleSubmit">
              {{ $trlMessage('button.enter') }}
            </base-button>
          </form-field>
        </form-row>
      </form>
    </template>
  </validation-provider>
</template>

<script>
import { mask } from 'vue-the-mask';
import { ValidationProvider, ValidationField } from 'vue-validate-form';
import { minLength } from '@vue-validate-form/validators';
import FormRow from '@/components/form/form-row.vue';
import FormField from '@/components/form/form-field.vue';
import BaseInput from '@/components/base-input/base-input.vue';
import BaseButton from '@/components/base-button/base-button.vue';
import { LoginAPI } from '@/api/login';
import { getCookie } from '@/utils/common';
import { sendMetrics } from '@/utils/metrics';

const BAD_CODE = 'ERR_BAD_REQUEST';
export default {
  name: 'PhoneCode',
  components: {
    ValidationProvider,
    ValidationField,
    BaseButton,
    FormRow,
    FormField,
    BaseInput
  },
  directives: {
    mask
  },
  emits: ['complete'],
  data() {
    return {
      codeRequested: false,
      timerCount: null,
      interval: null,
      errorMessage: '',
      loading: false
    };
  },
  mounted() {
    this.$refs.code.$el.focus();
  },
  methods: {
    onSubmit(values) {
      sendMetrics('enter');
      const params = {
        code: values.code,
        uuid: getCookie('uuid')
      };
      this.loading = true;
      LoginAPI.authUser(params)
        .then((resp) => {
          this.$emit('complete', resp);
        })
        .catch((err) => {
          this.setError(err);
          this.$refs.code.$el.focus();
          this.loading = false;
        });
    },
    resolver(values) {
      const result = {
        values,
        errors: {}
      };
      if (!minLength(values.code, 6)) {
        result.errors.code = [{ message: this.$trlMessage('error.code.length') }];
      }

      return result;
    },
    setError(err) {
      if (err.code === BAD_CODE) {
        this.errorMessage = this.$trlMessage('error.BAD_CODE');
      }
    },
    setValue() {
      if (this.errorMessage) {
        this.errorMessage = '';
      }
    }
  }
};
</script>

<style module>
.wrapper {
  .login {
    color: #069;
  }
}

.login {
  color: #c00;
}

.code {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
</style>
