import { ApiLayer } from '@/utils/api/api-layer';
import axios from '@/utils/api/client/axios';
class LoginLayer extends ApiLayer {
    getCode(data) {
        return this.methods.post('/auth/phones/otp', data);
    }
    authUser(data) {
        return this.methods.post('/auth/phones/validate', data);
    }
}
export const LoginAPI = new LoginLayer(axios);
