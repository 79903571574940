<template>
  <div :class="$style.root">
    <base-loader v-if="loading" />
    <slot v-else />
  </div>
</template>
<script>
import BaseLoader from '@/components/loader/loader.vue';
export default {
  name: 'PageLayout',
  components: {
    BaseLoader
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {}
};
</script>

<style module>
.root {
  background-color: #fff;
  max-width: 1280px;
  width: 100%;
  align-self: flex-start;
  min-height: 100px;
  flex-wrap: wrap;
  padding: 20px;
}
</style>
