<template>
  <div :class="$style.wrapper">
    <base-select :class="$style.select" :value="phoneCode" @change="selectCode">
      <option v-for="code in phoneCodesList" :key="code.code" :value="code.dial_code">
        {{ code.dial_code }}
      </option>
    </base-select>

    <base-input
      ref="tel"
      :id="id"
      :value="value"
      type="tel"
      v-mask="'####################'"
      :disabled="disabled"
      :has-error="hasError"
      :name="name"
      autofocus
      @input="onChange"
    />
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import BaseSelect from '@/components/base-select/select.vue';
import BaseInput from '@/components/base-input/base-input.vue';
import countryCodes from './countryCodes.json';
import { getLocalStorageItem } from '@/utils/local-storage';
export default {
  name: 'PhoneInput',
  components: {
    BaseInput,
    BaseSelect
  },
  directives: {
    mask
  },
  props: {
    id: {
      type: String || Number,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'setCode', 'focus'],
  data() {
    return {
      phoneCode: null,
      focusInput: true,
      code: ''
    };
  },
  computed: {
    phoneCodesList() {
      return countryCodes.sort((a, b) => a.dial_code.localeCompare(b.dial_code));
    }
  },
  watch: {
    hasError() {
      this.$refs.tel.$el.focus();
    }
  },
  mounted() {
    this.selectCode(getLocalStorageItem('phonecode') || '+7');
  },
  methods: {
    selectCode(code) {
      this.$refs.tel.$el.focus();
      this.phoneCode = code;
      this.$emit('setCode', code);
    },
    onChange(event) {
      this.$emit('input', event);
    }
  }
};
</script>

<style module>
.wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.select {
  border-radius: 6px;
  width: 100px;
  height: auto;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #c4edfa;
    border-color: #29a0ba;
  }
}
</style>
