<template>
  <span :class="$style.error">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'BaseError'
};
</script>

<style module>
.error {
  font-size: 14px;
  margin-top: 5px;
  line-height: 16px;
  display: block;
  color: #ff0000;
}
</style>
