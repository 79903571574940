<template>
  <div :class="className">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FormRow',
  props: {
    submit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    className() {
      return {
        [this.$style.root]: true,
        [this.$style.submit]: this.submit
      };
    }
  }
};
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
}

.root + .root {
  margin-top: 1.6rem;
}
.root + .submit {
  margin-top: 3.2rem;
}
</style>
