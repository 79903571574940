import { setLocalStorageItem, getLocalStorageItem } from '@/utils/local-storage';
import { EventPaymentType } from '@/types/event';
import { stringDateFull } from '@/utils/common';
/**
 * Обновление количества черновиков в хранилище
 */
function updateDraftList() {
    const draftList = getLocalStorageItem('draft') || {};
    const draftKeys = Object.keys(draftList);
    if (draftKeys.length > 5) {
        delete draftList[draftKeys[0]];
    }
    return draftList;
}
/**
 * Сохранение черновика
 * @param endDate
 * @param event
 * @param draftId
 * @param tariffGroupId
 * @param promoCode
 * @param isMe
 * @param draft
 */
export function saveDraft({ endDate, event, draftId, tariffGroupId, draft, promoCode, isMe }) {
    const draftList = updateDraftList();
    const tariff = event.tariff_groups.filter((el) => el.id.toString() === tariffGroupId.toString())[0];
    const draftKey = draftId || (+new Date()).toString();
    draftList[draftKey] = {
        number: draftKey,
        isMe,
        tariff_group: {
            id: tariffGroupId,
            name: tariff?.name,
            type: tariff?.type,
            date: endDate
        },
        event: {
            id: event.uuid,
            name: event.name
        },
        state: EventPaymentType.draft,
        created: stringDateFull(),
        promoCode,
        ...draft
    };
    setLocalStorageItem('draft', draftList);
}
/**
 * Удаление черновика
 * @param id
 */
export function removeDraft(id) {
    const draftList = getLocalStorageItem('draft') || {};
    delete draftList[id];
    setLocalStorageItem('draft', draftList);
}
/**
 * Получение черновика из хранилища
 * @param id
 */
export function getDraft(id) {
    const draftList = getLocalStorageItem('draft') || {};
    return draftList[id] || null;
}
