<template>
  <div :class="$style.root">
    <validation-field :name="field.id" v-for="(field, index) in $options.orgFields" :key="index">
      <template #default="{ modelValue, onChange, name, invalid }">
        <form-field :id="field.id" :class="field.class ? $style[field.class] : ''">
          <template #default="{ id }">
            <base-input
              :id="id"
              :placeholder="field.title"
              :has-error="invalid"
              :value="modelValue"
              :name="name"
              :type="field.type"
              autofocus
              @input="onChange($event)"
            />
            <validation-errors :name="field.id" />
          </template>
        </form-field>
      </template>
    </validation-field>
  </div>
</template>

<script>
import { ValidationField } from '@/components/ui/validate-form';
import BaseInput from '@/components/base-input/base-input.vue';
import { trlMessage } from '@/services/i18n';
import ValidationErrors from '@/components/ui/validation-errors.vue';
import FormField from '@/components/form/form-field.vue';

const orgFields = [
  {
    id: 'organization.legal_name',
    title: trlMessage('organization.name'),
    class: 'full'
  },
  {
    id: 'organization.inn',
    title: trlMessage('organization.inn'),
    class: 'col',
    type: 'number'
  },
  {
    id: 'organization.kpp',
    title: trlMessage('organization.kpp'),
    class: 'col',
    type: 'number'
  },
  {
    id: 'organization.ogrn',
    title: trlMessage('organization.ogrn'),
    class: 'col',
    type: 'number'
  },
  {
    id: 'organization.legal_address',
    title: trlMessage('organization.address.legal'),
    class: 'full'
  },
  {
    id: 'organization.actual_address',
    title: trlMessage('organization.address.actual'),
    class: 'full'
  },
  {
    id: 'organization.bank_name',
    title: trlMessage('organization.bank.name'),
    class: 'half'
  },
  {
    id: 'organization.bic',
    title: trlMessage('organization.bic'),
    class: 'half',
    type: 'number'
  },
  {
    id: 'organization.account',
    title: trlMessage('organization.account'),
    class: 'half',
    type: 'number'
  },
  {
    id: 'organization.correspondent_account',
    title: trlMessage('organization.correspondent'),
    class: 'half',
    type: 'number'
  }
];

export default {
  name: 'BaseOrganization',
  components: {
    FormField,
    ValidationErrors,
    ValidationField,
    BaseInput
  },
  props: {},
  orgFields,
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {}
};
</script>

<style module>
.root {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  margin: 20px 0 5px;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
}
.full {
  grid-column: span 6;
}

.half {
  grid-column: span 3;
}
.col {
  grid-column: span 2;
}
</style>
