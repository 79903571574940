<template>
  <svg
    :class="$style.logo"
    height="44"
    viewBox="0 0 178 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0461 0.6875H22.0449C10.2582 0.6875 0.703125 10.0382 0.703125 21.5728V22.4273C0.703125 33.9618 10.2582 43.3125 22.0449 43.3125H22.0461C33.8329 43.3125 43.3879 33.9618 43.3879 22.4273V21.5728C43.3879 10.0382 33.8329 0.6875 22.0461 0.6875Z"
      fill="#25CFE8"
    />
    <path
      d="M32.6741 11.1875H27.8783L22.3708 19.0979H21.8138L16.585 11.1875H11.6035L18.8127 21.8789L11.0156 32.8175H15.9352L21.6902 24.8763H22.2471L27.9402 32.8175H33.0763L25.2793 21.7862L32.6741 11.1875Z"
      fill="white"
    />
    <template v-if="isRussian">
      <path
        d="M69.84 12.8438H65.9036L61.3829 19.3366H60.9259L56.634 12.8438H52.5451L58.4625 21.6193L52.0625 30.5978H56.1006L60.8243 24.0797H61.2815L65.9544 30.5978H70.1701L63.7703 21.5432L69.84 12.8438Z"
        fill="#1D1D1F"
      />
      <path
        d="M96.002 22.8462H90.237V17.9766H87.0625V30.658H90.237V25.763H96.002V30.658H99.1765V17.9766H96.002V22.8462Z"
        fill="#1D1D1F"
      />
      <path
        d="M77.019 27.932C74.9874 27.932 73.5652 26.3342 73.5652 24.2799C73.5652 22.0479 74.9874 20.5769 77.019 20.5769C79.1523 20.5769 80.5745 22.0479 80.5745 24.2799C80.5745 26.3342 79.1523 27.932 77.019 27.932ZM80.6761 19.2579H80.5491C79.8888 18.3956 78.5429 17.6094 76.6381 17.6094C73.0572 17.6094 70.2891 20.5769 70.2891 24.3053C70.2891 27.9574 73.0572 30.8995 76.6381 30.8995C78.5429 30.8995 79.8888 30.1132 80.5491 29.251H80.6761L80.8538 30.5952H83.7744V17.9137H80.8538L80.6761 19.2579Z"
        fill="#1D1D1F"
      />
      <path
        d="M173.635 17.9766L169.953 26.1942H169.623L165.559 17.9766H161.75L167.972 30.1001L165.331 35.7306H169.14L177.445 17.9766H173.635Z"
        fill="#1D1D1F"
      />
      <path
        d="M154.72 27.9439C152.612 27.9439 151.139 26.422 151.139 24.3423C151.139 22.1865 152.612 20.69 154.72 20.69C156.854 20.69 158.352 22.1865 158.352 24.3423C158.352 26.422 156.854 27.9439 154.72 27.9439ZM154.72 17.6719C150.809 17.6719 147.914 20.6395 147.914 24.3677C147.914 28.02 150.809 30.9621 154.72 30.9621C158.707 30.9621 161.628 28.02 161.628 24.3677C161.628 20.6395 158.707 17.6719 154.72 17.6719Z"
        fill="#1D1D1F"
      />
      <path
        d="M134.379 24.4695C134.329 26.8535 133.567 27.589 132.119 27.589H131.891V30.6581C135.649 30.9116 137.452 29.0347 137.579 24.6725L137.681 20.8933H142.405V30.6581H145.579V17.9766H134.532L134.379 24.4695Z"
        fill="#1D1D1F"
      />
      <path
        d="M123.705 27.7689V20.8701C125.915 20.8701 127.489 22.1891 127.489 24.345C127.489 26.4247 125.915 27.7689 123.705 27.7689ZM120.531 27.7689C118.321 27.7689 116.747 26.4247 116.747 24.345C116.747 22.1891 118.321 20.8701 120.531 20.8701V27.7689ZM123.705 17.9281V12.9062H120.531V17.9281C116.544 17.9281 113.547 20.6419 113.547 24.345C113.547 28.0226 116.544 30.711 120.531 30.711V35.7836H123.705V30.711C127.692 30.711 130.689 28.0226 130.689 24.345C130.689 20.6419 127.692 17.9281 123.705 17.9281Z"
        fill="#1D1D1F"
      />
      <path
        d="M101.531 20.8932H105.468V30.658H108.642V20.8932H112.604V17.9766H101.531V20.8932Z"
        fill="#1D1D1F"
      />
    </template>
    <template v-else>
      <path
        d="M96.4543 17.5312C94.805 17.5312 93.2613 18.2909 92.6855 19.2341H92.5542L92.3713 17.8457H89.3872V30.9431H92.6586V24.3681C92.6586 22.0367 93.8106 20.6223 95.7737 20.6223C97.6319 20.6223 98.7575 21.9322 98.7575 24.3681V30.9431H102.029V24.211C102.029 19.8627 99.6994 17.5312 96.4543 17.5312Z"
        fill="#1D1D1F"
      />
      <path
        d="M82.7528 24.7611C82.7528 27.014 81.5749 28.1402 79.7168 28.1402C77.8583 28.1402 76.6804 27.014 76.6804 24.7611V17.8457H73.4087V24.7874C73.4087 29.2928 76.5235 31.2575 79.7168 31.2575C82.9097 31.2575 86.0245 29.2928 86.0245 24.7874V17.8457H82.7528"
        fill="#1D1D1F"
      />
      <path
        d="M110.112 26.149V20.7529H113.985V17.8452H110.112V13.916H106.866V17.8452H103.804V20.7529H106.866V26.3846C106.866 29.4496 108.332 31.2045 111.211 31.2045C112.389 31.2045 113.383 30.89 114.326 30.2878L113.148 27.5372C111.682 28.5064 110.112 28.1134 110.112 26.149Z"
        fill="#1D1D1F"
      />
      <path
        d="M66.5042 19.9097H58.1811V12.5488H54.5952V30.8853H58.1811V23.3411H66.5042V30.8853H70.0901V12.5488H66.5042"
        fill="#1D1D1F"
      />
      <path
        d="M141.963 28.1402C139.791 28.1402 138.273 26.5684 138.273 24.4207C138.273 22.1941 139.791 20.6486 141.963 20.6486C144.162 20.6486 145.706 22.1941 145.706 24.4207C145.706 26.5684 144.162 28.1402 141.963 28.1402ZM141.963 17.5312C137.932 17.5312 134.949 20.5963 134.949 24.4469C134.949 28.2187 137.932 31.2575 141.963 31.2575C146.073 31.2575 149.082 28.2187 149.082 24.4469C149.082 20.5963 146.073 17.5312 141.963 17.5312Z"
        fill="#1D1D1F"
      />
      <path
        d="M167.676 17.8457L164.928 25.4684H164.64L161.42 17.8457H159.117L155.95 25.4684H155.688L152.94 17.8457H149.512L154.432 30.9431H156.787L160.138 23.2156H160.4L163.75 30.9431H166.08L171 17.8457"
        fill="#1D1D1F"
      />
      <path d="M129.124 30.942H132.396V12.6055H129.124V30.942Z" fill="#1D1D1F" />
      <path
        d="M123.716 15.4096C124.422 15.4096 124.841 15.5667 125.391 15.9859L126.595 13.4975C125.757 12.7904 124.737 12.3975 123.454 12.3975C120.915 12.3975 119.031 13.9167 119.031 17.0077V17.8459H115.968V20.7535H119.031V30.9433H122.303V20.7535H126.176V17.8459H122.303V16.9552C122.303 15.8552 122.826 15.4096 123.716 15.4096Z"
        fill="#1D1D1F"
      />
    </template>
  </svg>
</template>

<script>
import { getCookie } from '@/utils/common';
export default {
  name: 'HFLogo',
  data() {
    return {
      lang: getCookie('lang')
    };
  },
  computed: {
    isRussian() {
      return this.lang === 'ru_RU';
    }
  }
};
</script>

<style module>
.logo {
  @media screen and (min-width: 600px) {
    height: 44px;
  }
  @media screen and (max-width: 600px) {
    height: 32px;
  }
}
</style>
