// Сохранение данных в Local Storage
export function setLocalStorageItem(key, value) {
    try {
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
        return true;
    }
    catch (error) {
        console.error('Error saving to Local Storage:', error);
        return false;
    }
}
// Получение данных из Local Storage
export function getLocalStorageItem(key) {
    try {
        const serializedValue = localStorage.getItem(key);
        return serializedValue ? JSON.parse(serializedValue) : null;
    }
    catch (error) {
        console.error('Error retrieving from Local Storage:', error);
        return null;
    }
}
// Удаление данных из Local Storage
export function removeLocalStorageItem(key) {
    try {
        localStorage.removeItem(key);
        return true;
    }
    catch (error) {
        console.error('Error removing from Local Storage:', error);
        return false;
    }
}
// Очистка всего Local Storage
export function clearLocalStorage() {
    try {
        localStorage.clear();
        return true;
    }
    catch (error) {
        console.error('Error clearing Local Storage:', error);
        return false;
    }
}
export function setToken(value, expiration) {
    const item = {
        value: value,
        expiry: expiration,
    };
    localStorage.setItem('token', JSON.stringify(item));
}
export function getToken() {
    const item = getLocalStorageItem('token');
    if (!item) {
        return null;
    }
    const now = new Date();
    const expiry = new Date(item.expiry);
    if (now.getTime() > expiry.getTime()) {
        clearLocalStorage();
        return null;
    }
    return item.value;
}
