import { ApiLayer } from '@/utils/api/api-layer';
import axios from '@/utils/api/client/axios';
class UserLayer extends ApiLayer {
    fetchUserInfo() {
        return this.methods.get(`/me`);
    }
    updateUserInfo(data) {
        return this.methods.put('/me', data);
    }
    /**
     * Создание организации
     * @param data
     */
    createOrganization(data) {
        return this.methods.post(`/me/organizations`, data);
    }
}
export const UserAPI = new UserLayer(axios);
