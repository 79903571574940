<template>
  <base-button @click="handleClick" :loading="loading">
    {{ $trlMessage('pay.text') }}
  </base-button>
</template>

<script>
import BaseButton from '@/components/base-button/base-button.vue';
import { PaymentAPI } from '@/api/payment';
import { sendMetrics } from '@/utils/metrics';
import { EventType } from '@/types/event';

export default {
  name: 'PayButton',
  components: {
    BaseButton
  },
  props: {
    ticketId: {
      type: String,
      required: true
    },
    initPay: {
      type: Boolean,
      default: false
    },
    personal: {
      type: Boolean,
      default: false
    },
    ticketType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      paymentParams: {}
    };
  },
  emits: ['success'],
  mounted() {
    if (document.getElementById('cloudPayment')) {
      return;
    }
    const script = document.createElement('script');
    script.id = 'cloudPayment';
    script.src = 'https://widget.cloudpayments.ru/bundles/cloudpayments.js';
    script.async = true;
    document.body.appendChild(script);
    if (this.initPay) {
      script.onload = this.initialPay;
    }
  },
  methods: {
    initialPay() {
      this.loading = true;
      PaymentAPI.fetchPaymentInfo(this.ticketId).then((resp) => {
        this.paymentParams = resp;
        this.payCloudpayments();
      });
    },
    handleClick() {
      this.initialPay();
    },
    payCloudpayments() {
      const widget = new window.cp.CloudPayments();
      const widgetProps = {
        ...this.paymentParams,
        skin: 'mini'
      };
      const type = EventType[this.ticketType];
      const ticketTypeTarget = `buy_nayom24_${type}`;

      sendMetrics(ticketTypeTarget);

      widget.pay('charge', widgetProps, {
        onSuccess: () => {
          this.handleSuccess();
        },
        onFail: (reason, options) => {
          console.error('CloudPayments:fail', reason, options);
          this.handleError(reason);
        },
        onComplete: (paymentResult, options) => {
          console.log('CloudPayments:complete', paymentResult, options);
          this.handleComplete(paymentResult);
        }
      });
    },
    handleSuccess() {
      this.loading = false;
      this.$emit('success');
    },
    handleComplete() {
      this.loading = false;
    },
    handleError(message) {
      this.loading = false;
      console.log(message);
    }
  }
};
</script>

<style module></style>
