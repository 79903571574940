<template>
  <a :href="`mailto:${link}`" target="_blank">{{ label }}</a>
</template>

<script>
import { trlMessage } from '@/services/i18n';

export default {
  name: 'HelpLink',
  components: {},
  props: {
    email: {
      type: String,
      default: 'club.support@huntflow.ru'
    },
    subject: {
      type: String,
      default: trlMessage('help.subject')
    },
    label: {
      type: String,
      default: trlMessage('help.link')
    }
  },
  computed: {
    link() {
      return `${this.email}?subject=${this.subject}`;
    }
  }
};
</script>

<style module></style>
