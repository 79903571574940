import { trlMessage } from '@/services/i18n';
export function translateErrors(errors) {
    const translatedErrors = {};
    if (Array.isArray(errors)) {
        errors.forEach((error) => {
            const location = error?.location?.variable;
            if (location) {
                const field = location ? location.replace('/', '').replace(/\//g, '.') : '';
                if (field) {
                    if (!translatedErrors.hasOwnProperty(field)) {
                        translatedErrors[field] = [];
                    }
                    //@ts-ignore
                    translatedErrors[field].push(trlMessage(error.detail || error.title || 'error.unknown'));
                }
            }
            else {
                if (!translatedErrors.hasOwnProperty(error.type)) {
                    translatedErrors[error.type] = [];
                }
                //@ts-ignore
                translatedErrors[error.type].push(trlMessage(error.detail || 'error.unknown'));
            }
        });
    }
    return translatedErrors;
}
export function getErrors(error) {
    const errors = error.response?.data?.errors ?? {
        common: 'error.unknown'
    };
    return translateErrors(errors);
}
