<template>
  <validation-field-array :name="fieldName" tag="section">
    <template #default="{ fields, name, remove, insert, append }">
      <base-checkbox
        v-show="!isMe"
        v-if="!disabled"
        :disabled="isMe"
        :value="isMe"
        :label="$trlMessage('event.go')"
        @input="setCurrentUser({ insert, append, remove }, fields, $event, append)"
      />
      <div v-for="(field, index) in fields" :key="field.id" :class="$style.participant">
        <div :class="$style.fields">
          <validation-field
            v-for="(userField, indexField) in $options.formField"
            :key="indexField"
            :class="$style.fieldWrapper"
            :name="`${name}.${index}.${userField.id}`"
          >
            <template #default="{ id, modelValue, invalid, name, onChange }">
              <div :class="$style.inputWrapper">
                <base-input
                  :id="id"
                  :placeholder="userField.placeholder"
                  :value="modelValue"
                  :class="$style.input"
                  :disabled="disabled || isMe"
                  :has-error="invalid"
                  :name="name"
                  @input="onChange"
                />
                <validation-errors :name="`${name}`" />
              </div>
            </template>
          </validation-field>
        </div>
        <base-button
          v-if="fields.length > 1 && canRemove"
          kind="warn"
          :class="$style.del"
          @click="remove(index)"
        />
      </div>
      <div
        v-if="showAttention"
        :class="$style.attention"
        v-html="$trlMessage('ticket.single', { url: '/profile' })"
      />
      <div :class="$style.total">
        <total-price
          :price="price"
          :promo-code="promoCode"
          :count-participants="fields.length"
          :nominal-price="nominalPrice"
          :editable="canRemove"
          :event-id="eventId"
          :tariff-id="tariffId"
          @set-code="handleSetPromoCode"
        />
        <base-button
          v-if="maxTicket > 1"
          @click="handleInsert(append)"
          kind="white"
          :disabled="fields.length >= maxTicket"
        >
          {{ $trlMessage('event.add.person') }}
        </base-button>
      </div>
    </template>
  </validation-field-array>
</template>

<script>
import { trlMessage } from '@/services/i18n';
import { ValidationField, ValidationFieldArray } from '@/components/ui/validate-form';
import BaseInput from '@/components/base-input/base-input.vue';
import BaseButton from '@/components/base-button/base-button.vue';
import BaseCheckbox from '@/components/checkbox/checkbox.vue';
import ValidationErrors from '@/components/ui/validation-errors.vue';
import TotalPrice from '../components/total-price.vue';
import { getUser } from '@/utils/user';

const formField = [
  {
    id: 'first_name',
    placeholder: trlMessage('profile.name')
  },
  {
    id: 'last_name',
    placeholder: trlMessage('profile.last_name')
  },
  {
    id: 'position',
    placeholder: trlMessage('profile.position')
  },
  {
    id: 'email',
    placeholder: trlMessage('profile.email')
  },
  {
    id: 'company',
    placeholder: trlMessage('profile.company')
  }
];

export default {
  name: 'EventParticipant',
  components: {
    ValidationErrors,
    BaseCheckbox,
    BaseInput,
    BaseButton,
    ValidationField,
    ValidationFieldArray,
    TotalPrice
  },
  props: {
    fieldName: {
      type: String,
      default: 'participants'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // Если билет можно купить только один, то выставляем галочку, заполняем поля и блокируем их
    isMe: {
      type: Boolean,
      default: false
    },
    price: {
      type: [Number, String],
      default: null
    },
    // Номинальная стоимость билета, без учета скидок
    nominalPrice: {
      type: [Number, String],
      default: null
    },
    maxTicket: {
      type: Number,
      default: null
    },
    canRemove: {
      type: Boolean,
      default: true
    },
    promoCode: {
      type: Object,
      default: () => ({})
    },
    eventId: {
      type: String,
      default: ''
    },
    tariffId: {
      type: Number,
      default: null
    },
    showAttention: {
      type: Boolean,
      default: false
    }
  },
  formField,
  emits: ['update', 'delete', 'set-code'],
  computed: {
    promoCodeName() {
      return this.customerCode?.name || '';
    }
  },
  methods: {
    handleInsert(append) {
      const userData = {
        first_name: '',
        last_name: '',
        email: '',
        position: '',
        company: ''
      };
      append(userData);
    },
    setCurrentUser(operations, participant, state) {
      const count = participant.length;
      const index = participant.findIndex((item) => item.email === '');
      const newUser = getUser();

      if (!state) {
        const removeIndex = participant.findIndex((item) => item.email === newUser.email);
        operations.remove(removeIndex);
        this.handleInsert(operations.append);
        return;
      }

      if (index !== -1) {
        operations.remove(index);
        operations.insert(index, newUser);
        return;
      }
      if (count < this.maxTicket) {
        operations.append(newUser);
      }
    },
    handleSetPromoCode(code) {
      this.$emit('set-code', code);
    }
  }
};
</script>

<style module>
.participant {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.fields {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  width: 100%;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }
}

.fieldWrapper {
  grid-column: span 1;
}
.input,
.inputWrapper {
  width: 100%;
}
.del {
  background-image: url('@/static/trash.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 48px;
  margin-left: 10px;
}

.attention {
  margin-top: 30px;
  margin-bottom: 30px;
  border-left: solid 3px #d8d7e0;
  padding-left: 10px;
}

.total {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 20px 0;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 16px 0;

    div {
      margin: 8px 0;
    }
  }
}
</style>
