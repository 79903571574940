<template>
  <vue-validation-errors :name="name">
    <template #default="{ errors }">
      <template v-if="all && errors.length">
        <base-error v-for="(error, index) in errors" :key="index">
          {{ error.message }}
        </base-error>
      </template>
      <base-error v-else-if="errors.length">
        {{ errors[0].message }}
      </base-error>
    </template>
  </vue-validation-errors>
</template>

<script>
import { ValidationErrors as VueValidationErrors } from 'vue-validate-form';

import BaseError from '@/components/error/error';

export default {
  name: 'ValidationErrors',
  components: { VueValidationErrors, BaseError },
  props: {
    name: {
      type: String,
      default: undefined
    },
    all: Boolean
  }
};
</script>
