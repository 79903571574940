<template>
  <page-layout>
    <div :class="$style.wrapper">
      <form-layout :title="title">
        <div :class="$style.forms">
          <phone-number @send="fetchCode" />
          <phone-code v-if="showInputCode" @complete="handleCodeComplete" />
        </div>
        <div :class="$style.help">
          <help-link />
        </div>
      </form-layout>
    </div>
  </page-layout>
</template>

<script>
import PhoneNumber from './components/phone-number.vue';
import PhoneCode from './components/phone-code.vue';
import FormLayout from '@/components/form/form-layout.vue';
import PageLayout from '@/components/page/page-layout.vue';
import HelpLink from '@/components/help-link/help-link.vue';
import { setCookie } from '@/utils/common';
import { setToken, removeLocalStorageItem } from '@/utils/local-storage';
import { fetchUser } from '@/utils/user';

export default {
  name: 'LoginPage',
  components: {
    PhoneNumber,
    PhoneCode,
    FormLayout,
    PageLayout,
    HelpLink
  },
  props: {},
  data() {
    return {
      showInputCode: false,
      title: ''
    };
  },
  mounted() {
    const redirect = this.$route.query?.redirect || '';
    const isEventRedirect = redirect ? redirect.split('/').indexOf('events') !== -1 : false;

    this.title = isEventRedirect
      ? this.$trlMessage('login.title.event')
      : this.$trlMessage('login.title.auth');
  },
  methods: {
    fetchCode() {
      this.showInputCode = true;
    },
    handleCodeComplete(token) {
      setCookie('nextRetry', '', { expires: -1 });
      setCookie('uuid', '', { expires: -1 });
      setToken(token.access_token, token.expiration_date);
      removeLocalStorageItem('phone');

      fetchUser().then(() => {
        this.$router.push({ name: 'orders' });
      });
    }
  }
};
</script>

<style module>
.wrapper {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
}

.title {
  line-height: 1.2em;
  text-align: center;
}

.forms {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.help {
  margin-top: 16px;
  text-align: center;
}
</style>
