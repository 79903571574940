<template>
  <validation-provider
    id="response"
    :default-values="phone"
    :resolver="resolver"
    @submit="onSubmit"
  >
    <template #default="{ handleSubmit, values }">
      <form
        method="post"
        @submit.prevent="handleSubmit"
        @keyup.enter="onEnter(values, handleSubmit)"
      >
        <form-row>
          <validation-field name="number">
            <template #default="{ modelValue, onChange, name, invalid }">
              <form-field :id="name" :error="errorMessage" :label="$trlMessage('login.number')">
                <template #default="{ id }">
                  <phone-input
                    ref="tel"
                    :id="id"
                    tabindex="1"
                    :has-error="invalid"
                    :value="modelValue"
                    :name="name"
                    :disabled="!!timerCount"
                    @setCode="setCode"
                    @input="onChangeInput(onChange, $event), setValue()"
                    @paste="onChangePaste(onChange, $event), setValue()"
                  />
                </template>
              </form-field>
            </template>
          </validation-field>
        </form-row>
        <form-row>
          <base-checkbox
            :class="$style.checkbox"
            :label="checkboxLabel"
            v-model="acceptPolicy"
            :disabled="sendCode"
          />
          <form-field id="submit">
            <base-button
              tabindex="2"
              :disabled="!!timerCount || !acceptPolicy"
              @click="onEnter(values, handleSubmit)"
            >
              {{ sendCodeText }}
            </base-button>
          </form-field>
        </form-row>
      </form>
    </template>
  </validation-provider>
</template>

<script>
import { ValidationProvider, ValidationField } from 'vue-validate-form';
import FormRow from '@/components/form/form-row.vue';
import FormField from '@/components/form/form-field.vue';
import BaseButton from '@/components/base-button/base-button.vue';
import { LoginAPI } from '@/api/login';
import { setCookie, getCookie } from '@/utils/common';
import { setLocalStorageItem, getLocalStorageItem } from '@/utils/local-storage';
import BaseCheckbox from '@/components/checkbox/checkbox.vue';
import { sendMetrics } from '@/utils/metrics';
import PhoneInput from '@/components/phone-input/phone-input.vue';

const BAD_PATTERN = 'value_error';
const MANY_REQUESTS = 'too_many_requests_error';
export default {
  name: 'PhoneNumber',
  components: {
    BaseCheckbox,
    ValidationProvider,
    ValidationField,
    BaseButton,
    FormRow,
    FormField,
    PhoneInput
  },
  emits: ['send'],
  data() {
    return {
      timerCount: null,
      interval: null,
      nextRetry: null,
      currDate: null,
      errorMessage: '',
      phone: {
        number: getLocalStorageItem('phone')
      },
      acceptPolicy: !!getLocalStorageItem('phone'),
      sendCode: false,
      selectedCode: null
    };
  },
  computed: {
    sendCodeText() {
      return this.timerCount === null
        ? this.$trlMessage('button.code')
        : this.$trlMessage('button.retry.code', {
            count: this.timerCount
          });
    },
    checkboxLabel() {
      return this.$trlMessage('accept.policy', { url: 'https://huntflow.ru/docs/privacy-policy' });
    }
  },
  mounted() {
    if (getCookie('nextRetry')) {
      this.nextRetry = decodeURIComponent(getCookie('nextRetry'));
      this.currDate = Date.now();
      this.setTimer();
      this.sendCode = true;
    }
  },
  methods: {
    onEnter(values, handleSubmit) {
      if (!this.acceptPolicy) {
        return;
      }
      this.sendCode = true;
      handleSubmit();
    },
    setCode(code) {
      this.selectedCode = code;
    },
    onSubmit(values) {
      if (!this.acceptPolicy) {
        return;
      }
      sendMetrics('code');
      const authParams = {
        phone: `${this.selectedCode}${values.number.replace(/\D/g, '')}`
      };
      setLocalStorageItem('phonecode', this.selectedCode);
      setLocalStorageItem('phone', values.number);
      LoginAPI.getCode(authParams)
        .then((resp) => {
          this.nextRetry = resp.next_retry_date;
          this.currDate = resp.current_date;
          this.setTimer();
          setCookie('uuid', resp.uuid);
          setCookie('nextRetry', resp.next_retry_date);
          this.$emit('send');
        })
        .catch((err) => {
          this.setErrors(err);
        });
    },
    setTimer() {
      const date1 = new Date(this.currDate);
      const date2 = new Date(this.nextRetry);
      const diffInMilliseconds = date2 - date1;
      const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
      if (diffInSeconds < 0) {
        return;
      }
      this.$emit('send');
      if (!this.timerCount && !!diffInSeconds) {
        this.timerCount = diffInSeconds;
        this.timer();
      }
    },
    timer() {
      setTimeout(() => {
        this.timerCount--;
        if (this.timerCount === 0) {
          this.timerCount = null;
        } else {
          this.timer();
        }
      }, 1000);
    },
    setValue() {
      if (this.errorMessage) {
        this.errorMessage = '';
      }
    },
    setErrors(error) {
      const ErrorCodeBody = error.response?.data?.errors?.[0];
      if (ErrorCodeBody?.type === BAD_PATTERN) {
        this.errorMessage = this.$trlMessage('error.BAD_PATTERN');
        return;
      }
      if (ErrorCodeBody.type === MANY_REQUESTS) {
        this.errorMessage = this.$trlMessage('error.ALREADY_SEND');
        this.nextRetry = ErrorCodeBody.detail.next_retry_date;
        this.currDate = ErrorCodeBody.detail.current_date;
        this.setTimer();
        this.$emit('send');
        return;
      }
      this.errorMessage = ErrorCodeBody.title;
    },
    formatPhoneNumber(value) {
      const formattedNumber = value.replace(/\D/g, '');
      return formattedNumber;
    },
    onChangeInput(onChange, value) {
      onChange(this.formatPhoneNumber(value));
    },
    onChangePaste(onChange, value) {
      let phone = value.clipboardData.getData('text');
      this.$nextTick(() => {
        onChange(this.formatPhoneNumber(phone));
      });
    }
  }
};
</script>

<style module>
.wrapper {
  .login {
    color: #069;
  }
}

.login {
  color: #c00;
}

.code {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.checkbox {
  margin-bottom: 16px;
}
</style>
