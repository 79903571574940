<template>
  <page-layout>
    <form-layout :title="title">
      <validation-provider
        :default-values="userInfo"
        :resolver="$options.resolver"
        @submit="onSubmit"
      >
        <template #default="{ handleSubmit }">
          <form method="post" @submit.prevent="handleSubmit" @keyup.enter="handleSubmit">
            <form-row v-for="(field, index) in $options.formField" :key="index">
              <validation-field :name="field.id">
                <template #default="{ modelValue, onChange, name, invalid }">
                  <form-field :id="field.id">
                    <template #default="{ id }">
                      <base-input
                        :id="id"
                        :placeholder="field.placeholder"
                        :has-error="invalid"
                        :value="modelValue"
                        :name="name"
                        autofocus
                        @input="onChange($event)"
                      />
                      <validation-errors :name="field.id" />
                    </template>
                  </form-field>
                </template>
              </validation-field>
            </form-row>
            <validation-field name="is_huntflow_client">
              <template #default="{ modelValue, name }">
                <form-field id="is_huntflow_client">
                  <template #default="{ id }">
                    <input type="hidden" :id="id" :name="name" :value="modelValue" />
                  </template>
                </form-field>
              </template>
            </validation-field>
            <form-row>
              <form-field id="submit">
                <base-button :disabled="false" @click="handleSubmit" :loading="loading">
                  {{ $trlMessage('button.save') }}
                </base-button>
              </form-field>
            </form-row>
          </form>
        </template>
      </validation-provider>
      <div v-if="!isRegistered" :class="$style.help">
        <help-link />
      </div>
    </form-layout>
  </page-layout>
</template>

<script>
import { object, string } from 'yup';
import {
  ValidationProvider,
  ValidationField,
  setSubmitError,
  yupResolver
} from '@/components/ui/validate-form';
import FormLayout from '@/components/form/form-layout.vue';
import PageLayout from '@/components/page/page-layout.vue';
import FormRow from '@/components/form/form-row.vue';
import BaseInput from '@/components/base-input/base-input.vue';
import FormField from '@/components/form/form-field.vue';
import BaseButton from '@/components/base-button/base-button.vue';
import ValidationErrors from '@/components/ui/validation-errors.vue';
import { trlMessage } from '@/services/i18n';
import { UserAPI } from '@/api/user';
import { getUser, updateUser, isRegistered } from '@/utils/user';
import HelpLink from '@/components/help-link/help-link.vue';
import { sendMetrics } from '@/utils/metrics';

const formField = [
  {
    id: 'first_name',
    placeholder: trlMessage('profile.name')
  },
  {
    id: 'last_name',
    placeholder: trlMessage('profile.last_name')
  },
  {
    id: 'email',
    placeholder: trlMessage('profile.email')
  },
  {
    id: 'position',
    placeholder: trlMessage('profile.position')
  },
  {
    id: 'company',
    placeholder: trlMessage('profile.company')
  }
];

export default {
  name: 'ProfilePage',
  components: {
    HelpLink,
    ValidationProvider,
    ValidationField,
    BaseButton,
    FormField,
    BaseInput,
    FormRow,
    FormLayout,
    PageLayout,
    ValidationErrors
  },
  props: {},
  formField,
  resolver: yupResolver(
    object({
      first_name: string().required(),
      email: string().email().required(),
      last_name: string().required(),
      position: string().required(),
      company: string().required()
    })
  ),
  data() {
    return {
      userInfo: {},
      loading: false,
      isRedirect: false
    };
  },
  computed: {
    title() {
      return this.isRedirect
        ? this.$trlMessage('login.header.event')
        : this.$trlMessage('login.header.auth');
    },
    isRegistered() {
      return isRegistered();
    }
  },
  mounted() {
    const redirect = this.$route.query?.redirect || '';
    this.isRedirect = redirect ? redirect.split('/').indexOf('events') !== -1 : false;

    this.userInfo = getUser();
  },
  methods: {
    onSubmit(user, { setError, focusInvalidField }) {
      this.loading = true;
      UserAPI.updateUserInfo(user)
        .then((resp) => {
          this.userInfo = resp;
          if (!this.isRegistered) {
            sendMetrics('registr');
          }
          updateUser(resp);
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          }
        })
        .catch((error) => {
          setSubmitError(error, { setError, focusInvalidField });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setValue() {}
  }
};
</script>

<style module>
.forms {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
.help {
  margin-top: 16px;
  text-align: center;
}
</style>
