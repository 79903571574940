// @ts-nocheck
import { setLocale, addMethod, string } from 'yup';
export { yupResolver } from '@vue-validate-form/resolvers';
import { trlMessage } from '@/services/i18n';
export { ValidationProvider, ValidationField, ValidationFieldArray } from 'vue-validate-form';
import { getErrors } from '@/utils/normalize-errors';
export { default as ValidationErrors } from './validation-errors.vue';
// eslint-disable-next-line
const VALID_EMAIL_REGEX = /^[^@\s]+@([^@\s]+\.)+[^@\s]{2,}$/i;
setLocale({
    mixed: {
        required: trlMessage('error.required')
    }
});
addMethod(string, 'email', function validateEmail(message = '') {
    return this.matches(VALID_EMAIL_REGEX, {
        message: message || trlMessage('error.not-correct-email'),
        name: 'email',
        excludeEmptyString: true
    });
});
export function setSubmitError(err, { setError, focusInvalidField }) {
    const errors = getErrors(err);
    Object.entries(errors).forEach(([key, values]) => {
        values.forEach((message) => {
            setError(key, { message, type: 'custom' });
        });
    });
    focusInvalidField();
}
