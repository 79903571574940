import { createRouter, createWebHistory } from 'vue-router';
import { captureMessage } from '@sentry/browser';
import LoginPage from '@/pages/login/login.vue';
import ProfilePage from '@/pages/cabinet/profile.vue';
import OrdersPage from '@/pages/cabinet/order/order-list.vue';
import EventPage from '@/pages/cabinet/order/event.vue';
import TicketPage from '@/pages/cabinet/order/ticket.vue';
import NotFoundPage from '@/pages/404/not-found.vue';
import { trlMessage } from '@/services/i18n';
import { isAuthorized, isRegistered, updateUser } from '@/utils/user';

/**
 * Проверка авторизации и регистрации пользователя
 * @param to
 * @param from
 */
function checkAuthentication(to, from) {
  let query = { ...to.query };

  if (Object.keys(query).length) {
    query = new URLSearchParams(query).toString();
    query = `&${query}`;
  } else {
    query = '';
  }

  if (!isAuthorized()) {
    window.location = `/?redirect=${to.path}${query}`;
    return false;
  }
  if (!isRegistered() && to.name !== 'profile') {
    window.location = `/profile?redirect=${from.query.redirect || to.path}${query}`;
    return false;
  }
  return true;
}

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginPage,
    meta: {
      title: trlMessage('page.authorization')
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfilePage,
    meta: {
      title: trlMessage('page.profile'),
      menu: true
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: OrdersPage,
    meta: {
      title: trlMessage('page.orders'),
      menu: true
    }
  },
  {
    path: '/events/:eventId/buy/:typeId',
    name: 'event-buy',
    component: EventPage,
    props: (route) => ({
      eventId: route.params.eventId,
      tariffGroupId: parseInt(route.params.typeId, 10)
    })
  },
  {
    path: '/ticket/:ticketId',
    name: 'ticket',
    component: TicketPage,
    props: (route) => ({
      ticketId: route.params.ticketId
    })
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: NotFoundPage,
    meta: {
      title: trlMessage('page.not-found')
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from) => {
  if (to.name === 'event-buy' && to.params.orderId) {
    document.title = `${trlMessage('page.order')} ${to.params.orderId}`;
  } else {
    document.title = to.meta.title || 'Huntflow Club';
  }

  if (to.name !== 'login') {
    return checkAuthentication(to, from);
  } else if (isAuthorized()) {
    return { name: 'orders' };
  } else {
    updateUser();
  }
  return true;
});

router.afterEach((to, from, failure) => {
  const redirect = to.query.redirect || from.query.redirect;

  if (failure) {
    captureMessage(`Failed to load page`, {
      extra: {
        from: from,
        to: to,
        failure: failure,
        isUserAuth: isAuthorized(),
        isRegistered: isRegistered(),
        redirect: redirect
      }
    });
    return;
  }

  if (!redirect) {
    return;
  }

  if (isAuthorized() && isRegistered()) {
    router.push({ path: redirect });
  }
});

router.onError((error) => {
  let localStorageData = '';
  let allCookies = '';

  try {
    allCookies = document.cookie.split(';');
  } catch (cookieError) {
    console.error(`Error while getting cookies: ${cookieError}`);
  }

  try {
    localStorageData = JSON.stringify(localStorage);
  } catch (localStorageError) {
    console.error(`Error while serializing localStorage: ${localStorageError}`);
  }

  captureMessage(`Router error`, {
    extra: {
      error: error,
      isUserAuth: isAuthorized(),
      isRegistered: isRegistered(),
      from: error?.from ? JSON.stringify(error.from) : '',
      to: error?.to ? JSON.stringify(error.to) : '',
      localStorageData: localStorageData,
      allCookies: allCookies
    }
  });
});

export default router;
