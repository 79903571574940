import './style.css';

import { createApp } from 'vue';
import { init as initSentry, browserTracingIntegration, captureException } from '@sentry/vue';

import App from './App.vue';
import { VueI18n } from './services/i18n';
import router from './router';
import { fetchUser } from '@/utils/user';
import { isAuthorized } from '@/utils/user';

const app = createApp(App);

initSentry({
  app,
  dsn: 'https://6fc044d51a9b4b0bad138d3bc437daed@sentry.huntflow.ru/61',
  integrations: [browserTracingIntegration({ router })],
  tracesSampleRate: 1.0
});

try {
  if (isAuthorized()) {
    await fetchUser();
  }
} catch (e) {
  captureException(e);
} finally {
  app.use(router);
  app.use(VueI18n);
  app.mount('#app');
}
