import Axios from 'axios';
import { getToken } from '@/utils/local-storage.ts';
import router from '@/router';
import { removeLocalStorageItem } from '@/utils/local-storage.ts';
import { CancelablePromise, isCancel as CancelablePromiseIsCancel } from '../cancelable-promise';
function createCancelTokenSource() {
    // @ts-ignore
    return Axios.CancelToken.source();
}
export function isCancel(exception) {
    return Axios.isCancel(exception) || CancelablePromiseIsCancel(exception);
}
const axiosInstance = Axios.create({
    baseURL: `/api/`
});
axiosInstance.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => Promise.reject(error));
axiosInstance.interceptors.response.use((response) => response.data, (error) => {
    if (error.response?.status === 401) {
        // Редирект на главную страницу при ошибке авторизации
        removeLocalStorageItem('token');
        router.push({ name: 'login' });
    }
    return Promise.reject(error);
});
const createAxiosInstance = (options) => {
    return {
        isCancel,
        createCancelTokenSource,
        getMethods(tokenSource) {
            return {
                get: (url, config = {}) => {
                    config.cancelToken = tokenSource.token;
                    return CancelablePromise.resolve(axiosInstance.get(url, config), tokenSource.cancel);
                },
                delete: (url, config = {}) => {
                    config.cancelToken = tokenSource.token;
                    return CancelablePromise.resolve(axiosInstance.delete(url, config), tokenSource.cancel);
                },
                put: (url, data, config = {}) => {
                    config.cancelToken = tokenSource.token;
                    return CancelablePromise.resolve(axiosInstance.put(url, data, config), tokenSource.cancel);
                },
                patch: (url, data, config = {}) => {
                    config.cancelToken = tokenSource.token;
                    return CancelablePromise.resolve(axiosInstance.patch(url, data, config), tokenSource.cancel);
                },
                post: (url, data, config = {}) => {
                    config.cancelToken = tokenSource.token;
                    return CancelablePromise.resolve(axiosInstance.post(url, data, config), tokenSource.cancel);
                }
            };
        }
    };
};
export default createAxiosInstance({});
