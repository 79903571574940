<template>
  <form-wrapper>
    <template #header>
      <h1 :class="$style.title" v-html="title"></h1>
      <p v-if="description">
        {{ description }}
      </p>
    </template>
    <slot />
  </form-wrapper>
</template>

<script>
import FormWrapper from './form-wrapper.vue';
export default {
  name: 'FormLayout',
  components: { FormWrapper },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: null
    }
  }
};
</script>

<style module>
.title {
  font-weight: 600;
  font-size: 2rem;
  line-height: 115%;
  letter-spacing: -0.01em;
  margin-top: 0;
}
</style>
