<template>
  <div :class="$style.root">
    <page-header />
    <div :class="$style.content">
      <slot />
    </div>
  </div>
</template>
<script>
import PageHeader from '@/components/page/page-header.vue';
export default {
  name: 'PageLayout',
  components: {
    PageHeader
  }
};
</script>

<style module>
.root {
  font-size: 1.6rem;
  line-height: 1.8rem;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #373546;
  padding-bottom: 200px;
}

.content {
  @media screen and (min-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding-top: 5%;
    padding-bottom: 10%;
  }
}
</style>
