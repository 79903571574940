export var EventType;
(function (EventType) {
    EventType["ONLINE"] = "online";
    EventType["OFFLINE"] = "offline";
    EventType["HYBRID"] = "hybrid";
})(EventType || (EventType = {}));
export var EventPaymentType;
(function (EventPaymentType) {
    EventPaymentType["created"] = "CREATED";
    EventPaymentType["canceled"] = "CANCELED";
    EventPaymentType["paid"] = "PAID";
    EventPaymentType["draft"] = "DRAFT";
})(EventPaymentType || (EventPaymentType = {}));
