<template>
  <div>
    <em v-if="promoCode" :class="$style.title">{{ title }}</em>
    <base-dropdown v-else-if="editable">
      <template #default="{ onToggle }">
        <base-button type="link" @click="handleShowInput(onToggle)">
          {{ $trlMessage('promo.code.input') }}
        </base-button>
      </template>
      <template #menu="{ onToggle }">
        <validation-provider
          :default-values="defaultValues"
          @submit="(event, error) => handleCheckCode({ event, error, onToggle })"
          @change="updateField"
        >
          <template #default="{ handleSubmit }">
            <div>
              <validation-field name="code">
                <template #default="{ id, modelValue, name, onChange }">
                  <base-input
                    ref="promo"
                    :id="id"
                    :value="modelValue"
                    :class="$style.inputWrapper"
                    :name="name"
                    :has-error="isInvalid"
                    @input="onChange"
                    @keyup.stop.enter="handleSubmit"
                  />
                  <div :class="$style.error">
                    <validation-errors name="code" />
                  </div>
                </template>
              </validation-field>
              <div :class="$style.btnGroup">
                <base-button :loading="loading" :disabled="isCodeEmpty" @click="handleSubmit">
                  {{ $trlMessage('button.apply') }}
                </base-button>
                <base-button kind="white" @click="handleCancel(onToggle)">
                  {{ $trlMessage('button.cancel') }}
                </base-button>
              </div>
            </div>
          </template>
        </validation-provider>
      </template>
    </base-dropdown>
    <base-button v-if="isShowingRemoveBtn" type="link" @click="handleRemove">
      {{ $trlMessage('button.remove') }}
    </base-button>
    <base-modal :show="showModal" @hide="handleHideModal">
      <template #content>{{ $trlMessage('promo.code.expired') }}</template>
      <template #footer>
        <base-button :class="$style.continueBtn" @click="handleHideModal">
          {{ $trlMessage('promo.code.expired.continue') }}
        </base-button>
      </template>
    </base-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationErrors } from '@/components/ui/validate-form';
import BaseDropdown from '@/components/base-dropdown/base-dropdown.vue';
import BaseButton from '@/components/base-button/base-button.vue';
import BaseInput from '@/components/base-input/base-input.vue';
import BaseModal from '@/components/base-modal/base-modal.vue';
import { PromoCodeAPI } from '@/api/promo-code';
import { ValidationField } from 'vue-validate-form';
export default {
  name: 'PromoCode',
  components: {
    ValidationField,
    BaseDropdown,
    BaseButton,
    BaseInput,
    BaseModal,
    ValidationProvider,
    ValidationErrors
  },
  props: {
    eventId: {
      type: String,
      default: ''
    },
    tariffId: {
      type: Number,
      default: null
    },
    promoCode: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      code: '',
      loading: false,
      defaultValues: {
        code: ''
      },
      isInvalid: false,
      showModal: false,
      // Флаг, что мы проверили валидность кода при вводе.
      // Нужен, чтобы знать, проверят ли валидность при заходе на страеицу с промо-кодом
      isCodeChecked: false
    };
  },
  emits: ['set-code'],
  computed: {
    isShowingRemoveBtn() {
      return this.editable && this.promoCode;
    },
    isCodeEmpty() {
      return !this.code.toString().length;
    },
    title() {
      return this.$trlMessage('promo.code.title', {
        code: this.promoCode.toString().toUpperCase()
      });
    }
  },
  watch: {
    promoCode(code) {
      // Проверяем код при инициализации только черновика
      if (!this.editable) {
        return;
      }
      if (!this.isCodeChecked && code) {
        PromoCodeAPI.fetchCodeInfo(this.eventId, this.tariffId, this.promoCode).catch(() => {
          this.showModal = true;
        });
      }
    }
  },
  methods: {
    handleCancel(onToggle) {
      this.code = '';
      this.isInvalid = false;
      onToggle();
    },
    handleCheckCode({ event, error, onToggle }) {
      const { setError } = error;
      this.code = event.code;
      this.loading = true;
      PromoCodeAPI.fetchCodeInfo(this.eventId, this.tariffId, this.code)
        .then((resp) => {
          this.code = '';
          this.$emit('set-code', resp);
          onToggle();
          this.isCodeChecked = true;
        })
        .catch((error) => {
          const errors = error.response?.data?.errors;

          if (errors && errors.length) {
            errors.forEach((error) => {
              setError('code', {
                message: this.$trlMessage(error?.detail ?? 'promo.code.invalid'),
                type: 'code'
              });
            });
          }
          this.isInvalid = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateField(val) {
      this.isInvalid = false;
      this.code = val.code;
    },
    handleRemove() {
      this.$emit('set-code', {});
    },
    handleHideModal() {
      this.showModal = false;
      this.handleRemove();
    },
    handleShowInput(toggle) {
      toggle();
      this.$nextTick(() => {
        this.$refs.promo.$el.focus();
      });
    }
  }
};
</script>

<style module>
.title {
  padding-right: 10px;
}
.inputWrapper {
  width: 260px;
  margin-bottom: 10px;
  margin-right: 0;
  text-transform: uppercase;
}

.error {
  margin-top: -8px;
  margin-bottom: 8px;
}

.btnGroup {
  display: flex;
  justify-content: space-between;
}

.continueBtn {
  display: block;
  margin: 0 auto;
}
</style>
