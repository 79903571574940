<template>
  <div :class="$style.root">
    <header :class="$style.header">
      <slot name="header" />
    </header>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FormWrapper'
};
</script>

<style module>
.root {
  padding: 3rem 3rem 7rem;

  @media screen and (min-width: 600px) {
    width: 512px;
    padding: 3.6rem 5.6rem 5.6rem 5.6rem;
    background: #ffffff;
    box-shadow: 0 10px 54px rgba(104, 101, 139, 0.14);
    border-radius: 12px;
  }
  @media screen and (max-width: 600px) {
    padding-top: 6rem;
  }
}

.header {
  margin-bottom: 3.2rem;

  p {
    font-size: 16px;
    line-height: 150%;
  }

  h1 + p {
    margin-top: 0.8rem;
  }
}
</style>
