// id счетчика метрики
const ymId = 37066580;
const metricPrefix = 'button_hfclub_';
/**
 * Отправление данных для метрик
 * @param eventName {String} название события
 */
export function sendMetrics(eventName) {
    const event = `${metricPrefix}${eventName}`;
    if (window.ym) {
        window.ym(ymId, 'reachGoal', event, {});
    }
    if (window.dataLayer) {
        window.dataLayer.push({
            event: "reachGoal",
            eventcategory: event,
            eventaction: "click",
        });
    }
    if (window.roistat) {
        window.roistat?.event?.send(event);
    }
}
