<template>
  <svg :width="sizeInPx" :height="sizeInPx" :class="$style.loader" :viewBox="viewBox">
    <circle :class="[$style.svg, $style.bg]" cx="30" cy="30" r="27"></circle>
    <circle :class="[$style.svg, $style.animate]" cx="30" cy="30" r="27"></circle>
  </svg>
</template>

<script>
export default {
  name: 'BaseLoader',
  props: {
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sizeInPx() {
      return this.small ? 33 : 63;
    },
    viewBox() {
      return this.small ? '0 0 30 30' : '0 0 60 60';
    }
  }
};
</script>

<style module>
.loader {
  display: flex;
  position: relative;
  align-content: space-around;
  justify-content: center;
  margin: 0 auto;
}
.svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  fill: none;
  stroke-width: 5px;
  stroke-linecap: round;
  stroke: #d8d8e5;
}
.bg {
  stroke-width: 5px;
  stroke: #f7f7fa;
}

.animate {
  stroke-dasharray: 242.6;
  animation: fill-animation 1s cubic-bezier(1, 1, 1, 1) 0s infinite;
}

@keyframes fill-animation {
  0% {
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 141.3;
    stroke-dashoffset: 141.3;
  }
  100% {
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 282.6;
  }
}
.animate {
  stroke-dasharray: 242.6;
  animation: fill-animation 1s cubic-bezier(1, 1, 1, 1) 0s infinite;
}
</style>
