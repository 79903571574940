<template>
  <select v-bind="$attrs" :value="currentValue" :class="$style.select" v-on="inputListeners">
    <slot></slot>
  </select>
</template>

<script>
export default {
  name: 'BaseSelect',
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  emits: ['update:modelValue', 'change'],
  computed: {
    inputListeners() {
      return {
        change: (event) => {
          this.$emit('update:modelValue', event.target.value);
          this.$emit('change', event.target.value, event);
        }
      };
    },
    currentValue: {
      get() {
        return this.value !== '' ? this.value : this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
        this.$emit('change', val);
      }
    }
  }
};
</script>

<style module>
.select {
  padding: 4px 30px 3px 10px;
  box-sizing: border-box;
  margin: 0;
  border-radius: 3px;
  vertical-align: top;
  outline: 0 none;
  display: inline-block;

  font-size: 16px;
  line-height: 19px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #e3e3e3;
  background-color: #f2f2f2;
  background-repeat: no-repeat;
  background-position: right center;
  height: 30px;
  visibility: visible;

  background-image: url(@/static/select/triangle_transp.png);
  background-size: 18px 5px;

  &:focus {
    border-color: #2cc8df;
  }

  &:disabled {
    opacity: 0.7;
  }
}

.select[disabled] {
  color: #cccccc;
  cursor: default;
}
</style>
