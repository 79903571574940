<template>
  <header v-if="showHeader" :class="$style.root">
    <a :class="$style.link" href="/">
      <hf-logo />
    </a>
    <div>
      <club-menu />
    </div>
    <slot></slot>
  </header>
</template>

<script>
import HfLogo from '@/components/logo/hf-logo.vue';
import ClubMenu from '@/components/menu/menu.vue';
import { isRegistered } from '@/utils/user';

export default {
  name: 'PageHeader',
  components: {
    HfLogo,
    ClubMenu
  },
  computed: {
    showHeader() {
      return isRegistered();
    }
  }
};
</script>

<style module>
.root {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  background-color: #ffffff;
  box-shadow: 0 4px 14px rgb(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  padding: 0 2.4rem;
  height: 7.2rem;
}
</style>
