<template>
  <page-layout>
    <page-inner :loading="init">
      <h4>{{ title }}</h4>

      <strong :class="$style.personal">{{ $trlMessage('event.personal.info') }}</strong>
      <em :class="$style.passport" v-if="isCorporateTariff">
        {{ $trlMessage('event.passport.info') }}
      </em>
      <validation-provider
        :resolver="resolver"
        :default-values="defaultValues"
        @submit="onSubmit"
        @change="updateDraft"
      >
        <template #default="{ handleSubmit }">
          <form method="post" @submit.prevent="handleSubmit" @keyup.prevent.enter="handleSubmit">
            <event-participant
              :price="price"
              :max-ticket="maxTicket"
              :event-id="eventId"
              :promo-code="promoCode"
              :is-me="isMe"
              :tariff-id="tariffGroupId"
              :show-attention="isMe"
              @set-code="handlePromoCode"
            />
            <div v-if="hasPaymentTypes" :class="$style.payment">
              <h3 :class="$style.title">{{ $trlMessage('payment.type.title') }}</h3>
              <div :class="$style.radioGroup">
                <label>
                  <input
                    type="radio"
                    name="paymentType"
                    v-model="selectedPaymentTypes"
                    :value="$options.PaymentType.card"
                  />
                  {{ $trlMessage('payment.card') }}
                </label>
                <label>
                  <input
                    type="radio"
                    name="paymentType"
                    v-model="selectedPaymentTypes"
                    :value="$options.PaymentType.bill"
                  />
                  {{ $trlMessage('payment.bill') }}
                </label>
              </div>
              <em v-if="isCardPayment" v-html="$trlMessage('card.type.info')" />
            </div>
            <section>
              <base-select
                v-if="organizationList?.length > 1 && isBillPayment"
                v-model="selectedOrganizationId"
                :class="$style.select"
              >
                <option v-for="org in organizationList" :key="org.id" :value="org.id">
                  {{ org.legal_name }}
                </option>
              </base-select>

              <template v-if="isBillPayment">
                <h3 :class="$style.title">{{ $trlMessage('organization.info') }}</h3>
                <base-organization />
                <div :class="$style.document">
                  {{ $trlMessage('document.receive.title') }}
                </div>
                <base-select v-model="documentReceivingMethod" :class="$style.select">
                  <option v-for="doc in $options.documentTypeList" :key="doc.id" :value="doc.id">
                    {{ doc.title }}
                  </option>
                </base-select>
              </template>
              <div :class="$style.checkboxGroup">
                <base-checkbox
                  :label="acceptOfferLabel"
                  v-model="acceptOffer"
                  :value="acceptOffer"
                />
                <base-checkbox
                  :label="$trlMessage('accept.third')"
                  v-model="acceptThird"
                  :value="acceptThird"
                />

                <div :class="$style.error">
                  <validation-errors name="common" />
                  <validation-errors name="code" />
                </div>
              </div>

              <div :class="$style.bntGroup">
                <base-button v-if="isDraft" kind="warn" @click="handleRemoveDraft(true)">
                  {{ $trlMessage('button.remove.draft') }}
                </base-button>
              </div>
              <base-button
                :disabled="disabledBtn"
                :loading="loading"
                :class="$style.btn"
                @click="handleSubmit"
              >
                {{ payButtonLabel }}
              </base-button>
            </section>
          </form>
        </template>
      </validation-provider>
    </page-inner>
  </page-layout>
</template>

<script>
import { object, string, array } from 'yup';
import {
  ValidationProvider,
  ValidationErrors,
  yupResolver,
  setSubmitError
} from '@/components/ui/validate-form';
import PageLayout from '@/components/page/page-layout.vue';
import PageInner from '@/components/page/page-inner.vue';
import BaseButton from '@/components/base-button/base-button.vue';
import BaseCheckbox from '@/components/checkbox/checkbox.vue';
import BaseOrganization from '@/components/organization/organization.vue';
import BaseSelect from '@/components/base-select/select.vue';
import { getOrganization } from '@/utils/user';
import { UserAPI } from '@/api/user';
import { EventAPI } from '@/api/event';
import { OrderAPI } from '@/api/order';
import { PaymentType } from '@/types/payment';
import { DocumentsType } from '@/types/documents';
import { trlMessage } from '@/services/i18n';
import { EventPaymentType } from '@/types/event';
import { getDraft, saveDraft, removeDraft } from '@/services/draft';
import { sendMetrics } from '@/utils/metrics';
import { getUser } from '@/utils/user';

import EventParticipant from './components/event-participant.vue';
const userData = {
  first_name: '',
  last_name: '',
  email: '',
  position: '',
  company: ''
};

const documentTypeList = [
  {
    id: DocumentsType.diadoc,
    title: trlMessage('document.diadoc')
  },
  {
    id: DocumentsType.personal,
    title: trlMessage('document.personal')
  },
  {
    id: DocumentsType.email,
    title: trlMessage('document.email')
  }
];

const orgFields = {
  legal_name: '',
  inn: '',
  kpp: '',
  ogrn: null,
  legal_address: '',
  actual_address: '',
  bank_name: '',
  bic: '',
  account: '',
  correspondent_account: ''
};

export default {
  name: 'EventPage',
  components: {
    ValidationProvider,
    ValidationErrors,
    BaseSelect,
    BaseOrganization,
    BaseCheckbox,
    PageLayout,
    PageInner,
    BaseButton,
    EventParticipant
  },
  PaymentType,
  documentTypeList,
  props: {
    eventId: {
      type: String,
      required: true
    },
    tariffGroupId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      participantList: [{ ...userData }],
      init: true,
      event: {},
      // список организаций клиента
      organizationList: [...getOrganization()],
      tariff: {
        number_of_participants: null,
        payment_types: []
      },
      selectedPaymentTypes: PaymentType.card,
      selectedOrganizationId: null,
      documentReceivingMethod: DocumentsType.diadoc,
      acceptOffer: false,
      acceptThird: false,
      // Лимит неоплаченных заявок
      limitOrders: false,
      loading: false,
      draft: {},
      // ключ для проверки, нужно ли сохранять черновик или нет
      saveCurrentDraft: true,
      promoCode: {},
      // флаг для возможности покупки только одного билета
      isMe: false
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.saveCurrentDraft) {
      this.handleSaveDraft();
    }
    next(true);
  },
  computed: {
    resolver() {
      const resolver = {};
      if (this.isBillPayment) {
        resolver.organization = object({
          legal_name: string().required(),
          inn: string()
            .min(10, trlMessage('error.validation.minLength', { limit: 10 }))
            .max(12, trlMessage('error.validation.maxLength', { limit: 12 }))
            .required(),
          kpp: string().length(9, trlMessage('error.validation.length', { limit: 9 })),
          legal_address: string().required(),
          actual_address: string().required(),
          bank_name: string().required(),
          ogrn: string()
            .nullable()
            .length(13, trlMessage('error.validation.length', { limit: 13 })),
          bic: string()
            .length(9, trlMessage('error.validation.length', { limit: 9 }))
            .required(),
          account: string()
            .length(20, trlMessage('error.validation.length', { limit: 20 }))
            .required(),
          correspondent_account: string()
            .required()
            .length(20, trlMessage('error.validation.length', { limit: 20 }))
        });
      }

      function createRequiredString() {
        return string().when('email', (email) => {
          if (email[0]) {
            return string().required();
          }
          return string();
        });
      }

      resolver.participants = array().of(
        object({
          email: string(),
          first_name: createRequiredString(),
          last_name: createRequiredString(),
          position: createRequiredString(),
          company: createRequiredString()
        })
      );
      return yupResolver(object(resolver));
    },
    title() {
      return this.$trlMessage('event.title.book', { title: this.event.name });
    },
    maxTicket() {
      return this.tariff.number_of_participants;
    },
    participantCount() {
      return this.participantList.length;
    },
    isBillPayment() {
      return this.selectedPaymentTypes === PaymentType.bill;
    },
    isCardPayment() {
      return this.selectedPaymentTypes === PaymentType.card;
    },
    price() {
      return this.tariff.price || 0;
    },
    hasPaymentTypes() {
      return this.tariff.payment_types.length > 1;
    },
    organization() {
      const defaultOrganization =
        this.organizationList.filter(
          (organization) => parseInt(organization.id) === parseInt(this.selectedOrganizationId)
        )[0] || orgFields;
      return this.isDraft
        ? getDraft(this.draftId)?.draft?.organization || defaultOrganization
        : defaultOrganization;
    },
    defaultValues() {
      return {
        organization: this.organization,
        participants: this.participantList
      };
    },
    disabledBtn() {
      return !this.acceptOffer || !this.acceptThird || this.limitOrders;
    },
    acceptOfferLabel() {
      return this.$trlMessage('accept.offer', {
        url: 'https://huntflow.ru/docs/events/terms-of-participation'
      });
    },
    isDraft() {
      return this.$route?.query?.type === EventPaymentType.draft;
    },
    draftId() {
      return this.isDraft ? this.$route?.query?.draftId : null;
    },
    isCorporateTariff() {
      return this.tariff.number_of_participants > 1;
    },
    payButtonLabel() {
      return this.isBillPayment ? this.$trlMessage('invoice.create') : this.$trlMessage('pay.text');
    }
  },
  mounted() {
    if (this.isDraft) {
      this.setDraft();
    }

    if (!this.eventId || !this.tariffGroupId) {
      this.$router.push({ name: 'orders' });
    }
    this.selectedOrganizationId = this.isDraft
      ? this.selectedOrganizationId || this.organizationList[0]?.id
      : this.organizationList[0]?.id;
    Promise.all([
      EventAPI.fetchEventById(this.eventId),
      EventAPI.fetchEventTariffById(this.eventId, this.tariffGroupId)
    ])
      .then(([event, tariff]) => {
        this.event = event;
        this.tariff = tariff;
        this.selectedPaymentTypes = this.isDraft
          ? this.selectedPaymentTypes
          : tariff.payment_types[0];
        this.isMe = !this.isCorporateTariff;
      })
      .catch(() => {
        this.saveCurrentDraft = false;
        this.$router.push({ name: 'orders' });
      })
      .finally(() => {
        this.init = false;
      });
  },
  methods: {
    async onSubmit(value, { setError, focusInvalidField }) {
      if (this.disabledBtn) {
        return;
      }
      const event = {
        event_uuid: this.event.uuid,
        tariff_group_id: this.tariffGroupId,
        payment_type: this.selectedPaymentTypes,
        number_of_participants: value.participants.length,
        participants: value.participants.filter((participant) => participant.email),
        promo_code: this.promoCode?.code || null
      };
      if (this.isBillPayment) {
        event.document_receiving_method = this.documentReceivingMethod;
      }

      sendMetrics('buy_nayom24');

      this.loading = true;

      try {
        if (this.isBillPayment) {
          const org = value.organization;
          org.ogrn = org.ogrn || null;
          await UserAPI.createOrganization(value.organization).then((resp) => {
            event.organization_id = resp.id;
          });
        }
        await OrderAPI.createOrder(event).then((resp) => {
          if (this.isDraft) {
            this.handleRemoveDraft();
          }
          this.saveCurrentDraft = false;
          this.$router.push({
            name: 'ticket',
            params: { ticketId: resp.number },
            hash: '#pay'
          });
        });
        this.loading = false;
      } catch (error) {
        console.error('Произошла ошибка: ', error?.response);
        this.loading = false;
        if (error?.response?.status === 403) {
          this.limitOrders = true;
          setError('common', { message: this.$trlMessage('error.unpaid.orders'), type: 'custom' });
        } else {
          setSubmitError(error, { setError, focusInvalidField });
        }
      }
    },
    updateDraft(values) {
      this.draft = values;
    },
    /**
     * Установка данных из черновика
     */
    setDraft() {
      const currentDraft = getDraft(this.draftId);

      if (!currentDraft) {
        return;
      }
      this.isMe = currentDraft.isMe;
      this.participantList = this.isMe
        ? [getUser()]
        : currentDraft.draft.participants ?? this.participantList;
      this.selectedPaymentTypes = currentDraft.payment_type;
      this.selectedOrganizationId = currentDraft.organization_id ?? this.organizationList[0]?.id;
      this.documentReceivingMethod = currentDraft.receiving_method ?? DocumentsType.diadoc;
      this.draft = {
        participants: currentDraft.draft.participants,
        organization: currentDraft.draft.organization
      };
      this.promoCode = currentDraft.promoCode;
    },
    /**
     * Сохранение черновика
     */
    handleSaveDraft() {
      const params = {
        endDate: this.tariff.end_date,
        event: this.event,
        draftId: this.draftId,
        tariffGroupId: this.tariffGroupId,
        draft: {
          number_of_participants: this.draft.participants.length,
          total_cost: this.price * this.draft.participants.length,
          payment_type: this.selectedPaymentTypes,
          organization_id: this.selectedOrganizationId,
          receiving_method: this.documentReceivingMethod,
          draft: this.draft
        },
        isMe: this.isMe,
        promoCode: this.promoCode
      };

      saveDraft(params);
    },
    /**
     * Удаление черновика
     * @param redirect
     */
    handleRemoveDraft(redirect = false) {
      removeDraft(this.draftId);
      this.saveCurrentDraft = false;
      if (redirect) {
        this.$router.push({ name: 'orders' });
      }
    },
    /**
     * Данные промокода
     * @param code
     */
    handlePromoCode(code) {
      this.promoCode = code;
    }
  }
};
</script>

<style module>
.personal {
  margin: 16px 0 10px;
  display: block;
}

.passport {
  margin-bottom: 20px;
  display: block;
}

.title {
  margin-bottom: 4px;
}

.payment {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  label {
    margin-bottom: 10px;
  }
}
.select {
  margin: 10px 0;
}
.btn {
  margin-top: 20px;
}

.radioGroup {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.checkboxGroup {
  margin: 20px 0 10px;
}

.error {
  margin: 20px 0 10px;
}

.bntGroup {
  display: flex;
  margin: 16px 0 16px;
}

.document {
  margin: 20px 0 0;
}
</style>
