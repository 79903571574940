<template>
  <div>
    <label :class="$style.label">
      <input v-model="isChecked" :disabled="disabled" type="checkbox" @change="handleChange" />
      <span v-html="label" />
    </label>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'input'],
  data() {
    return {
      isChecked: this.modelValue || this.value || false
    };
  },
  watch: {
    initialValue(val) {
      this.isChecked = val;
    }
  },
  mounted() {
    if (this.isChecked) {
      this.$emit('input', this.isChecked);
    }
  },
  methods: {
    handleChange() {
      this.$emit('input', this.isChecked);
      this.$emit('update:modelValue', this.isChecked);
    }
  }
};
</script>

<style module>
.label {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}

input {
  margin-right: 8px;
}
</style>
