<template>
  <input
    :type="type"
    :class="className"
    v-bind="$attrs"
    v-on="listeners"
    :value="currentValue"
    @change="updateInput"
    @input="updateInput"
  />
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    hasError: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  emits: ['update:modelValue', 'input'],
  computed: {
    className() {
      return {
        [this.$style.root]: true,
        [this.$style.hasError]: this.hasError
      };
    },
    listeners() {
      return {
        input: (event) => {
          this.$emit('update:modelValue', event.target.value);
          this.$emit('input', event.target.value);
        }
      };
    },
    currentValue: {
      get() {
        return this.value !== '' ? this.value : this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
        this.$emit('input', val);
      }
    }
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value);
      this.$emit('input', event.target.value);
    }
  }
};
</script>

<style module>
.root {
  font: inherit;
  font-size: 1.6rem;
  line-height: 1.8rem;

  padding: 0 1.2rem;
  height: 4.8rem;
  background: #f6f6f8;
  border: 1px solid #d8d7e0;
  border-radius: 6px;
  width: 100%;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #c4edfa;
    border-color: #29a0ba;
  }

  &.hasError {
    border-color: #db525e;
  }

  &.hasError:focus {
    box-shadow: 0 0 0 3px #f5cccf;
  }

  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
  }
}
</style>
