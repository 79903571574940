import { ref } from 'vue';
import { UserAPI } from '@/api/user';
import { getToken } from '@/utils/local-storage';
// Создаем ref для пользователя
const user = ref(undefined);
export function isAuthorized() {
    return !!getToken();
}
export function isRegistered() {
    return user.value?.registration_complete;
}
export async function fetchUser() {
    if (!user.value) {
        user.value = await UserAPI.fetchUserInfo().catch(() => undefined);
    }
}
export function getUser() {
    // Возвращаем копию объекта пользователя, чтобы предотвратить нежелательные мутации
    return { ...user.value };
}
export function getOrganization() {
    return user.value?.organizations;
}
export function updateUser(userInfo = undefined) {
    user.value = userInfo;
}
